import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { RuoloUtente } from 'operatn-api-client';

import rootRedirect from './utils/rootRedirect';
import beforeEach from './utils/beforeEach';
import infos from './utils/infos';
import { bindQuery } from './utils/bindQuery';

/* PUBLIC */
const NotFound = () => import(/* webpackChunkName: "public" */ '@/views/not-found/NotFoundView.vue');
const Login = () => import(/* webpackChunkName: "public" */ '@/views/login/LoginView.vue');
const PasswordRecovery = () => import(/* webpackChunkName: "public" */'@/views/password-recovery/PasswordRecoveryView.vue');
const PasswordRecoveryToken = () => import(/* webpackChunkName: "public" */'@/views/password-recovery/token/PasswordRecoveryTokenView.vue');
const ContrattiEmailTokenFirma = () => import(/* webpackChunkName: "public" */ '@/views/contratti/email-token/token/firma/ContrattiEmailTokenFirmaView.vue');

/* ROOT */
const Root = () => import(/* webpackChunkName: "root" */ '@/views/root/RootView.vue');
const RootBar = () => import(/* webpackChunkName: "root" */ '@/views/root/RootBarView.vue');
const RootMenu = () => import(/* webpackChunkName: "root" */ '@/views/root/RootMenuView.vue');
const RootUserInfo = () => import(/* webpackChunkName: "root" */ '@/views/root/user-info/RootUserInfoView.vue');
const RootUtenti = () => import(/* webpackChunkName: "root" */ '@/views/root/utenti/RootUtentiView.vue');
const RootTipiStanza = () => import(/* webpackChunkName: "root" */ '@/views/root/tipi-stanza/RootTipiStanzaView.vue');
const RootTipiFabbricato = () => import(/* webpackChunkName: "root" */ '@/views/root/tipi-fabbricato/RootTipiFabbricatoView.vue');
const RootQuietanzianti = () => import(/* webpackChunkName: "root" */ '@/views/root/quietanzianti/RootQuietanziantiView.vue');
const RootTipiStudente = () => import(/* webpackChunkName: "root" */ '@/views/root/tipi-studente/RootTipiStudenteView.vue');
const RootContiRicavi = () => import(/* webpackChunkName: "root" */ '@/views/root/conti-ricavi/RootContiRicaviView.vue');
const RootTipiTariffa = () => import(/* webpackChunkName: "root" */ '@/views/root/tipi-tariffa/RootTipiTariffaView.vue');
const RootTipiOspite = () => import(/* webpackChunkName: "root" */ '@/views/root/tipi-ospite/RootTipiOspiteView.vue');
const RootTipiDocumentoIdentita = () => import(/* webpackChunkName: "root" */ '@/views/root/tipi-documento-identita/RootTipiDocumentoIdentitaView.vue');
const RootTipiContratto = () => import(/* webpackChunkName: "root" */ '@/views/root/tipi-contratto/RootTipiContrattoView.vue');
const RootTipiBolletta = () => import(/* webpackChunkName: "root" */ '@/views/root/tipi-bolletta/RootTipiBollettaView.vue');
const RootTariffe = () => import(/* webpackChunkName: "root" */ '@/views/root/tariffe/RootTariffeView.vue');
const RootFabbricati = () => import(/* webpackChunkName: "root" */ '@/views/root/fabbricati/RootFabbricatiView.vue');
const RootFabbricatiId = () => import(/* webpackChunkName: "root" */ '@/views/root/fabbricati/id/RootFabbricatiIdView.vue');
const RootFabbricatiIdStanzeId = () => import(/* webpackChunkName: "root" */ '@/views/root/fabbricati/id/stanze/id/RootFabbricatiIdStanzeIdView.vue');
const RootDipartimentiUnitn = () => import(/* webpackChunkName: "root" */ '@/views/root/dipartimenti-unitn/RootDipartimentiUnitnView.vue');
const RootTabellone = () => import(/* webpackChunkName: "root" */ '@/views/root/tabellone/RootTabelloneView.vue');
const RootTabelloneEsporta = () => import(/* webpackChunkName: "root" */ '@/views/root/tabellone/esporta/RootTabelloneEsportaView.vue');
const RootTabelloneCronologia = () => import(/* webpackChunkName: "root" */ '@/views/root/tabellone/cronologia/RootTabelloneCronologiaView.vue');
const RootContabilita = () => import(/* webpackChunkName: "root" */ '@/views/root/contabilita/RootContabilitaView.vue');
const RootContabilitaInvia = () => import(/* webpackChunkName: "root" */ '@/views/root/contabilita/invia/RootContabilitaInviaView.vue');
const RootContabilitaEstrai = () => import(/* webpackChunkName: "root" */ '@/views/root/contabilita/estrai/RootContabilitaEstraiView.vue');
const RootContabilitaCronologia = () => import(/* webpackChunkName: "root" */ '@/views/root/contabilita/cronologia/RootContabilitaCronologiaView.vue');
const RootContratti = () => import(/* webpackChunkName: "root" */ '@/views/root/contratti/RootContrattiView.vue');
const RootContrattiProvvisori = () => import(/* webpackChunkName: "root" */ '@/views/root/contratti/provvisori/RootContrattiProvvisoriView.vue');
const RootContrattiDaFirmare = () => import(/* webpackChunkName: "root" */ '@/views/root/contratti/da-firmare/RootContrattiDaFirmareView.vue');
const RootContrattiDaVisionare = () => import(/* webpackChunkName: "root" */ '@/views/root/contratti/da-visionare/RootContrattiDaVisionareView.vue');
const RootContrattiFuturi = () => import(/* webpackChunkName: "root" */ '@/views/root/contratti/futuri/RootContrattiFuturiView.vue');
const RootContrattiAttivi = () => import(/* webpackChunkName: "root" */ '@/views/root/contratti/attivi/RootContrattiAttiviView.vue');
const RootContrattiTerminati = () => import(/* webpackChunkName: "root" */ '@/views/root/contratti/terminati/RootContrattiTerminatiView.vue');
const RootContrattiId = () => import(/* webpackChunkName: "root" */ '@/views/root/contratti/id/RootContrattiIdView.vue');
const RootRicercaStanze = () => import(/* webpackChunkName: "root" */ '@/views/root/ricerca-stanze/RootRicercaStanzeView.vue');
const RootControlli = () => import(/* webpackChunkName: "root" */ '@/views/root/controlli/RootControlliView.vue');
const RootPresidenti = () => import(/* webpackChunkName: "root" */ '@/views/root/presidenti/RootPresidentiView.vue');
const RootPresidentiId = () => import(/* webpackChunkName: "root" */ '@/views/root/presidenti/id/RootPresidentiIdView.vue');
const RootOspiti = () => import(/* webpackChunkName: "root" */ '@/views/root/ospiti/RootOspitiView.vue');
const RootOspitiId = () => import(/* webpackChunkName: "root" */ '@/views/root/ospiti/id/RootOspitiIdView.vue');
const RootPortinerie = () => import(/* webpackChunkName: "root" */ '@/views/root/portinerie/RootPortinerieView.vue');
const RootLog = () => import(/* webpackChunkName: "root" */ '@/views/root/log/RootLogView.vue');
const RootNotFound = () => import(/* webpackChunkName: "root" */ '@/views/root/not-found/RootNotFoundView.vue');


/* ADMIN */
const Admin = () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminView.vue');
const AdminBar = () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminBarView.vue');
const AdminMenu = () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminMenuView.vue');
const AdminUserInfo = () => import(/* webpackChunkName: "admin" */ '@/views/admin/user-info/AdminUserInfoView.vue');
const AdminUtenti = () => import(/* webpackChunkName: "admin" */ '@/views/admin/utenti/AdminUtentiView.vue');
const AdminTipiStanza = () => import(/* webpackChunkName: "admin" */ '@/views/admin/tipi-stanza/AdminTipiStanzaView.vue');
const AdminTipiFabbricato = () => import(/* webpackChunkName: "admin" */ '@/views/admin/tipi-fabbricato/AdminTipiFabbricatoView.vue');
const AdminQuietanzianti = () => import(/* webpackChunkName: "admin" */ '@/views/admin/quietanzianti/AdminQuietanziantiView.vue');
const AdminTipiStudente = () => import(/* webpackChunkName: "admin" */ '@/views/admin/tipi-studente/AdminTipiStudenteView.vue');
const AdminContiRicavi = () => import(/* webpackChunkName: "admin" */ '@/views/admin/conti-ricavi/AdminContiRicaviView.vue');
const AdminTipiTariffa = () => import(/* webpackChunkName: "admin" */ '@/views/admin/tipi-tariffa/AdminTipiTariffaView.vue');
const AdminTipiOspite = () => import(/* webpackChunkName: "admin" */ '@/views/admin/tipi-ospite/AdminTipiOspiteView.vue');
const AdminTipiDocumentoIdentita = () => import(/* webpackChunkName: "admin" */ '@/views/admin/tipi-documento-identita/AdminTipiDocumentoIdentitaView.vue');
const AdminTipiContratto = () => import(/* webpackChunkName: "admin" */ '@/views/admin/tipi-contratto/AdminTipiContrattoView.vue');
const AdminTipiBolletta = () => import(/* webpackChunkName: "admin" */ '@/views/admin/tipi-bolletta/AdminTipiBollettaView.vue');
const AdminTariffe = () => import(/* webpackChunkName: "admin" */ '@/views/admin/tariffe/AdminTariffeView.vue');
const AdminFabbricati = () => import(/* webpackChunkName: "admin" */ '@/views/admin/fabbricati/AdminFabbricatiView.vue');
const AdminFabbricatiId = () => import(/* webpackChunkName: "admin" */ '@/views/admin/fabbricati/id/AdminFabbricatiIdView.vue');
const AdminFabbricatiIdStanzeId = () => import(/* webpackChunkName: "admin" */ '@/views/admin/fabbricati/id/stanze/id/AdminFabbricatiIdStanzeIdView.vue');
const AdminDipartimentiUnitn = () => import(/* webpackChunkName: "admin" */ '@/views/admin/dipartimenti-unitn/AdminDipartimentiUnitnView.vue');
const AdminTabellone = () => import(/* webpackChunkName: "admin" */ '@/views/admin/tabellone/AdminTabelloneView.vue');
const AdminTabelloneEsporta = () => import(/* webpackChunkName: "admin" */ '@/views/admin/tabellone/esporta/AdminTabelloneEsportaView.vue');
const AdminTabelloneCronologia = () => import(/* webpackChunkName: "admin" */ '@/views/admin/tabellone/cronologia/AdminTabelloneCronologiaView.vue');
const AdminContabilita = () => import(/* webpackChunkName: "admin" */ '@/views/admin/contabilita/AdminContabilitaView.vue');
const AdminContabilitaInvia = () => import(/* webpackChunkName: "admin" */ '@/views/admin/contabilita/invia/AdminContabilitaInviaView.vue');
const AdminContabilitaEstrai = () => import(/* webpackChunkName: "admin" */ '@/views/admin/contabilita/estrai/AdminContabilitaEstraiView.vue');
const AdminContabilitaCronologia = () => import(/* webpackChunkName: "admin" */ '@/views/admin/contabilita/cronologia/AdminContabilitaCronologiaView.vue');
const AdminContratti = () => import(/* webpackChunkName: "admin" */ '@/views/admin/contratti/AdminContrattiView.vue');
const AdminContrattiProvvisori = () => import(/* webpackChunkName: "admin" */ '@/views/admin/contratti/provvisori/AdminContrattiProvvisoriView.vue');
const AdminContrattiDaFirmare = () => import(/* webpackChunkName: "admin" */ '@/views/admin/contratti/da-firmare/AdminContrattiDaFirmareView.vue');
const AdminContrattiDaVisionare = () => import(/* webpackChunkName: "admin" */ '@/views/admin/contratti/da-visionare/AdminContrattiDaVisionareView.vue');
const AdminContrattiFuturi = () => import(/* webpackChunkName: "admin" */ '@/views/admin/contratti/futuri/AdminContrattiFuturiView.vue');
const AdminContrattiAttivi = () => import(/* webpackChunkName: "admin" */ '@/views/admin/contratti/attivi/AdminContrattiAttiviView.vue');
const AdminContrattiTerminati = () => import(/* webpackChunkName: "admin" */ '@/views/admin/contratti/terminati/AdminContrattiTerminatiView.vue');
const AdminContrattiId = () => import(/* webpackChunkName: "admin" */ '@/views/admin/contratti/id/AdminContrattiIdView.vue');
const AdminRicercaStanzeView = () => import(/* webpackChunkName: "admin" */ '@/views/admin/ricerca-stanze/AdminRicercaStanzeView.vue');
const AdminControlliView = () => import(/* webpackChunkName: "admin" */ '@/views/admin/controlli/AdminControlliView.vue');
const AdminPresidenti = () => import(/* webpackChunkName: "admin" */ '@/views/admin/presidenti/AdminPresidentiView.vue');
const AdminPresidentiId = () => import(/* webpackChunkName: "admin" */ '@/views/admin/presidenti/id/AdminPresidentiIdView.vue');
const AdminOspiti = () => import(/* webpackChunkName: "admin" */ '@/views/admin/ospiti/AdminOspitiView.vue');
const AdminOspitiId = () => import(/* webpackChunkName: "admin" */ '@/views/admin/ospiti/id/AdminOspitiIdView.vue');
const AdminPortinerie = () => import(/* webpackChunkName: "admin" */ '@/views/admin/portinerie/AdminPortinerieView.vue');
const AdminLog = () => import(/* webpackChunkName: "admin" */ '@/views/admin/log/AdminLogView.vue');
const AdminNotFound = () => import(/* webpackChunkName: "admin" */ '@/views/admin/not-found/AdminNotFoundView.vue');

/* RAGIONERIA */
const RagioneriaControlliView = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/controlli/RagioneriaControlliView.vue');
const Ragioneria = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/RagioneriaView.vue');
const RagioneriaBar = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/RagioneriaBarView.vue');
const RagioneriaMenu = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/RagioneriaMenuView.vue');
const RagioneriaUserInfo = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/user-info/RagioneriaUserInfoView.vue');
const RagioneriaQuietanzianti = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/quietanzianti/RagioneriaQuietanziantiView.vue');
const RagioneriaContiRicavi = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/conti-ricavi/RagioneriaContiRicaviView.vue');
const RagioneriaTipiOspite = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/tipi-ospite/RagioneriaTipiOspiteView.vue');
const RagioneriaTipiContratto = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/tipi-contratto/RagioneriaTipiContrattoView.vue');
const RagioneriaTipiBolletta = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/tipi-bolletta/RagioneriaTipiBollettaView.vue');
const RagioneriaTariffe = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/tariffe/RagioneriaTariffeView.vue');
const RagioneriaContabilita = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/contabilita/RagioneriaContabilitaView.vue');
const RagioneriaContabilitaInvia = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/contabilita/invia/RagioneriaContabilitaInviaView.vue');
const RagioneriaContabilitaEstrai = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/contabilita/estrai/RagioneriaContabilitaEstraiView.vue');
const RagioneriaContabilitaCronologia = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/contabilita/cronologia/RagioneriaContabilitaCronologiaView.vue');
const RagioneriaContratti = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/contratti/RagioneriaContrattiView.vue');
const RagioneriaContrattiProvvisori = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/contratti/provvisori/RagioneriaContrattiProvvisoriView.vue');
const RagioneriaContrattiDaFirmare = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/contratti/da-firmare/RagioneriaContrattiDaFirmareView.vue');
const RagioneriaContrattiDaVisionare = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/contratti/da-visionare/RagioneriaContrattiDaVisionareView.vue');
const RagioneriaContrattiFuturi = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/contratti/futuri/RagioneriaContrattiFuturiView.vue');
const RagioneriaContrattiAttivi = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/contratti/attivi/RagioneriaContrattiAttiviView.vue');
const RagioneriaContrattiTerminati = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/contratti/terminati/RagioneriaContrattiTerminatiView.vue');
const RagioneriaContrattiId = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/contratti/id/RagioneriaContrattiIdView.vue');
const RagioneriaOspiti = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/ospiti/RagioneriaOspitiView.vue');
const RagioneriaOspitiId = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/ospiti/id/RagioneriaOspitiIdView.vue');
const RagioneriaPortinerie = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/portinerie/RagioneriaPortinerieView.vue');
const RagioneriaNotFound = () => import(/* webpackChunkName: "ragioneria" */ '@/views/ragioneria/not-found/RagioneriaNotFoundView.vue');

/* SPORTELLO */
const Sportello = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/SportelloView.vue');
const SportelloBar = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/SportelloBarView.vue');
const SportelloMenu = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/SportelloMenuView.vue');
const SportelloUserInfo = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/user-info/SportelloUserInfoView.vue');
const SportelloRicercaStanze = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/ricerca-stanze/SportelloRicercaStanzeView.vue');
const SportelloControlli = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/controlli/SportelloControlliView.vue');
const SportelloQuietanzianti = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/quietanzianti/SportelloQuietanziantiView.vue');
const SportelloTipiStudente = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/tipi-studente/SportelloTipiStudenteView.vue');
const SportelloTipiContratto = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/tipi-contratto/SportelloTipiContrattoView.vue');
const SportelloTipiBolletta = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/tipi-bolletta/SportelloTipiBollettaView.vue');
const SportelloFabbricati = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/fabbricati/SportelloFabbricatiView.vue');
const SportelloFabbricatiId = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/fabbricati/id/SportelloFabbricatiIdView.vue');
const SportelloFabbricatiIdStanzeId = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/fabbricati/id/stanze/id/SportelloFabbricatiIdStanzeIdView.vue');
const SportelloDipartimentiUnitn = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/dipartimenti-unitn/SportelloDipartimentiUnitnView.vue');
const SportelloTabellone = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/tabellone/SportelloTabelloneView.vue');
const SportelloTabelloneEsporta = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/tabellone/esporta/SportelloTabelloneEsportaView.vue');
const SportelloTabelloneCronologia = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/tabellone/cronologia/SportelloTabelloneCronologiaView.vue');
const SportelloContratti = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/contratti/SportelloContrattiView.vue');
const SportelloContrattiProvvisori = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/contratti/provvisori/SportelloContrattiProvvisoriView.vue');
const SportelloContrattiDaFirmare = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/contratti/da-firmare/SportelloContrattiDaFirmareView.vue');
const SportelloContrattiDaVisionare = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/contratti/da-visionare/SportelloContrattiDaVisionareView.vue');
const SportelloContrattiFuturi = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/contratti/futuri/SportelloContrattiFuturiView.vue');
const SportelloContrattiAttivi = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/contratti/attivi/SportelloContrattiAttiviView.vue');
const SportelloContrattiTerminati = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/contratti/terminati/SportelloContrattiTerminatiView.vue');
const SportelloContrattiId = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/contratti/id/SportelloContrattiIdView.vue');
const SportelloOspiti = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/ospiti/SportelloOspitiView.vue');
const SportelloOspitiId = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/ospiti/id/SportelloOspitiIdView.vue');
const SportelloPortinerie = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/portinerie/SportelloPortinerieView.vue');
const SportelloNotFound = () => import(/* webpackChunkName: "sportello" */ '@/views/sportello/not-found/SportelloNotFoundView.vue');


/* PORTINERIA */
const Portineria = () => import(/* webpackChunkName: "portineria" */ '@/views/portineria/PortineriaView.vue');
const PortineriaBar = () => import(/* webpackChunkName: "portineria" */ '@/views/portineria/PortineriaBarView.vue');
const PortineriaMenu = () => import(/* webpackChunkName: "portineria" */ '@/views/portineria/PortineriaMenuView.vue');
const PortineriaRicercaStanze = () => import(/* webpackChunkName: "portineria" */ '@/views/portineria/ricerca-stanze/PortineriaRicercaStanzeView.vue');
const PortineriaControlli = () => import(/* webpackChunkName: "portineria" */ '@/views/portineria/controlli/PortineriaControlliView.vue');
const PortineriaTabellone = () => import(/* webpackChunkName: "portineria" */ '@/views/portineria/tabellone/PortineriaTabelloneView.vue');
const PortineriaTabelloneEsporta = () => import(/* webpackChunkName: "portineria" */ '@/views/portineria/tabellone/esporta/PortineriaTabelloneEsportaView.vue');
const PortineriaTabelloneCronologia = () => import(/* webpackChunkName: "portineria" */ '@/views/portineria/tabellone/cronologia/PortineriaTabelloneCronologiaView.vue');
const PortineriaOspiti = () => import(/* webpackChunkName: "portineria" */ '@/views/portineria/ospiti/PortineriaOspitiView.vue');
const PortineriaOspitiId = () => import(/* webpackChunkName: "portineria" */ '@/views/portineria/ospiti/id/PortineriaOspitiIdView.vue');
const PortineriaPortinerie = () => import(/* webpackChunkName: "portineria" */ '@/views/portineria/portinerie/PortineriaPortinerieView.vue');
const PortineriaNotFound = () => import(/* webpackChunkName: "portineria" */ '@/views/portineria/not-found/PortineriaNotFoundView.vue');

/* CONTROLLO ALLOGGI */
const ControlloAlloggi = () => import(/* webpackChunkName: "controllo-alloggi" */ '@/views/controllo-alloggi/ControlloAlloggiView.vue');
const ControlloAlloggiBar = () => import(/* webpackChunkName: "controllo-alloggi" */ '@/views/controllo-alloggi/ControlloAlloggiBarView.vue');
const ControlloAlloggiMenu = () => import(/* webpackChunkName: "controllo-alloggi" */ '@/views/controllo-alloggi/ControlloAlloggiMenuView.vue');
const ControlloAlloggiRicercaStanze = () => import(/* webpackChunkName: "controllo-alloggi" */ '@/views/controllo-alloggi/ricerca-stanze/ControlloAlloggiRicercaStanzeView.vue');
const ControlloAlloggiControlli = () => import(/* webpackChunkName: "controllo-alloggi" */ '@/views/controllo-alloggi/controlli/ControlloAlloggiControlliView.vue');
const ControlloAlloggiTabellone = () => import(/* webpackChunkName: "controllo-alloggi" */ '@/views/controllo-alloggi/tabellone/ControlloAlloggiTabelloneView.vue');
const ControlloAlloggiTabelloneEsporta = () => import(/* webpackChunkName: "controllo-alloggi" */ '@/views/controllo-alloggi/tabellone/esporta/ControlloAlloggiTabelloneEsportaView.vue');
const ControlloAlloggiTabelloneCronologia = () => import(/* webpackChunkName: "controllo-alloggi" */ '@/views/controllo-alloggi/tabellone/cronologia/ControlloAlloggiTabelloneCronologiaView.vue');
const ControlloAlloggiOspiti = () => import(/* webpackChunkName: "controllo-alloggi" */ '@/views/controllo-alloggi/ospiti/ControlloAlloggiOspitiView.vue');
const ControlloAlloggiOspitiId = () => import(/* webpackChunkName: "controllo-alloggi" */ '@/views/controllo-alloggi/ospiti/id/ControlloAlloggiOspitiIdView.vue');
const ControlloAlloggiNotFound = () => import(/* webpackChunkName: "controllo-alloggi" */ '@/views/controllo-alloggi/not-found/ControlloAlloggiNotFoundView.vue');


Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'root',
    redirect: rootRedirect
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/password-recovery',
    name: 'ask-password-recovery',
    component: PasswordRecovery
  },
  {
    path: '/password-recovery/:token',
    name: 'recover-password',
    component: PasswordRecoveryToken,
    props: true
  },
  {
    path: '/contratti/email-token/:token/firma',
    name: 'contratti-email-token-firma',
    component: ContrattiEmailTokenFirma,
    props: true
  },
  {
    path: '/root',
    components: {
      default: Root,
      bar: RootBar,
      menu: RootMenu
    },
    meta: { authentication: [RuoloUtente.ROOT] },
    children: [
      {
        path: '',
        redirect: 'utenti'
      },
      {
        path: 'user-info',
        name: 'root-user-info',
        component: RootUserInfo
      },
      {
        path: 'utenti',
        name: 'root-utenti',
        component: RootUtenti,
        meta: {
          infoText: infos.utenti
        }
      },
      {
        path: 'tipi-stanza',
        name: 'root-tipi-stanza',
        component: RootTipiStanza,
        meta: {
          infoText: infos.tipiStanza
        }
      },
      {
        path: 'tipi-fabbricato',
        name: 'root-tipi-fabbricato',
        component: RootTipiFabbricato,
        meta: {
          infoText: infos.tipiFabbricato
        }
      },
      {
        path: 'quietanzianti',
        name: 'root-quietanzianti',
        component: RootQuietanzianti
      },
      {
        path: 'tipi-studente',
        name: 'root-tipi-studente',
        component: RootTipiStudente,
        meta: {
          infoText: infos.tipiStudente
        }
      },
      {
        path: 'conti-ricavi',
        name: 'root-conti-ricavi',
        component: RootContiRicavi
      },
      {
        path: 'tipi-ospite',
        name: 'root-tipi-ospite',
        component: RootTipiOspite,
        meta: {
          infoText: infos.tipiOspite
        }
      },
      {
        path: 'tipi-documento-identita',
        name: 'root-tipi-documento-identita',
        component: RootTipiDocumentoIdentita,
      },
      {
        path: 'tipi-tariffa',
        name: 'root-tipi-tariffa',
        component: RootTipiTariffa,
        meta: {
          infoText: infos.tipiTariffa
        }
      },
      {
        path: 'tipi-contratto',
        name: 'root-tipi-contratto',
        component: RootTipiContratto,
        meta: {
          infoText: infos.tipiContratto
        }
      },
      {
        path: 'tipi-bolletta',
        name: 'root-tipi-bolletta',
        component: RootTipiBolletta,
        meta: {
          infoText: infos.tipiBolletta
        }
      },
      {
        path: 'dipartimenti-unitn',
        name: 'root-dipartimenti-unitn',
        component: RootDipartimentiUnitn
      },
      {
        path: 'tariffe',
        name: 'root-tariffe',
        component: RootTariffe
      },
      {
        path: 'fabbricati',
        name: 'root-fabbricati',
        component: RootFabbricati
      },
      {
        path: 'fabbricati/:fid',
        name: 'root-fabbricati-id',
        component: RootFabbricatiId,
        props: true
      },
      {
        path: 'fabbricati/:fid/stanze/:sid',
        name: 'root-fabbricati-id-stanze-id',
        component: RootFabbricatiIdStanzeId,
        props: true
      },
      {
        path: 'tabellone',
        component: RootTabellone,
        children: [
          {
            path: '',
            redirect: 'esporta'
          },
          {
            path: 'esporta',
            name: 'root-tabellone-esporta',
            component: RootTabelloneEsporta
          },
          {
            path: 'cronologia',
            name: 'root-tabellone-cronologia',
            component: RootTabelloneCronologia
          }
        ]
      },
      {
        path: 'contabilita',
        component: RootContabilita,
        children: [
          {
            path: '',
            redirect: 'invia'
          },
          {
            path: 'invia',
            name: 'root-contabilita-invia',
            component: RootContabilitaInvia
          },
          {
            path: 'estrai',
            name: 'root-contabilita-estrai',
            component: RootContabilitaEstrai
          },
          {
            path: 'cronologia',
            name: 'root-contabilita-cronologia',
            component: RootContabilitaCronologia
          }
        ]
      },
      {
        path: 'contratti',
        component: RootContratti,
        children: [
          {
            path: '',
            redirect: 'provvisori'
          },
          {
            path: 'provvisori',
            name: 'root-contratti-provvisori',
            component: RootContrattiProvvisori
          },
          {
            path: 'da-firmare',
            name: 'root-contratti-da-firmare',
            component: RootContrattiDaFirmare,
            meta: { infoText: infos.contrattiDaFirmare }
          },
          {
            path: 'da-visionare',
            name: 'root-contratti-da-visionare',
            component: RootContrattiDaVisionare
          },
          {
            path: 'futuri',
            name: 'root-contratti-futuri',
            component: RootContrattiFuturi
          },
          {
            path: 'attivi',
            name: 'root-contratti-attivi',
            component: RootContrattiAttivi
          },
          {
            path: 'terminati',
            name: 'root-contratti-terminati',
            component: RootContrattiTerminati
          },
        ]
      },
      {
        path: 'contratti/:cid',
        name: 'root-contratti-id-bollette',
        component: RootContrattiId,
        props: true
      },
      {
        path: 'ricerca-stanze',
        name: 'root-ricerca-stanze',
        component: RootRicercaStanze
      },
      {
        path: 'controlli',
        name: 'root-controlli',
        component: RootControlli
      },
      {
        path: 'presidenti',
        name: 'root-presidenti',
        component: RootPresidenti
      },
      {
        path: 'presidenti/:id',
        name: 'root-presidenti-id',
        component: RootPresidentiId,
        props: true
      },
      {
        path: 'ospiti',
        name: 'root-ospiti',
        component: RootOspiti,
        props: bindQuery('createDialogOpen')
      },
      {
        path: 'ospiti/:id',
        name: 'root-ospiti-id',
        component: RootOspitiId,
        props: true
      },
      {
        path: 'portinerie',
        name: 'root-portinerie',
        component: RootPortinerie
      },
      {
        path: 'log',
        name: 'log',
        component: RootLog
      },
      {
        path: '*',
        name: 'root-not-found',
        meta: { noElevation: true },
        component: RootNotFound
      },
    ]
  },
  {
    path: '/admin',
    components: {
      default: Admin,
      bar: AdminBar,
      menu: AdminMenu
    },
    meta: { authentication: [RuoloUtente.ADMIN] },
    children: [
      {
        path: '',
        redirect: 'utenti'
      },
      {
        path: 'controlli',
        name: 'admin-controlli',
        component: AdminControlliView
      },
      {
        path: 'ricerca-stanze',
        name: 'admin-ricerca-stanze',
        component: AdminRicercaStanzeView
      },
      {
        path: 'user-info',
        name: 'admin-user-info',
        component: AdminUserInfo
      },
      {
        path: 'utenti',
        name: 'admin-utenti',
        component: AdminUtenti,
        meta: {
          infoText: infos.utenti
        }
      },
      {
        path: 'tipi-stanza',
        name: 'admin-tipi-stanza',
        component: AdminTipiStanza,
        meta: {
          infoText: infos.tipiStanza
        }
      },
      {
        path: 'tipi-fabbricato',
        name: 'admin-tipi-fabbricato',
        component: AdminTipiFabbricato,
        meta: {
          infoText: infos.tipiFabbricato
        }
      },
      {
        path: 'quietanzianti',
        name: 'admin-quietanzianti',
        component: AdminQuietanzianti
      },
      {
        path: 'tipi-studente',
        name: 'admin-tipi-studente',
        component: AdminTipiStudente,
        meta: {
          infoText: infos.tipiStudente
        }
      },
      {
        path: 'conti-ricavi',
        name: 'admin-conti-ricavi',
        component: AdminContiRicavi
      },
      {
        path: 'tipi-tariffa',
        name: 'admin-tipi-tariffa',
        component: AdminTipiTariffa,
        meta: {
          infoText: infos.tipiTariffa
        }
      },
      {
        path: 'tipi-ospite',
        name: 'admin-tipi-ospite',
        component: AdminTipiOspite,
        meta: {
          infoText: infos.tipiOspite
        }
      },
      {
        path: 'tipi-documento-identita',
        name: 'admin-tipi-documento-identita',
        component: AdminTipiDocumentoIdentita,
      },
      {
        path: 'tipi-contratto',
        name: 'admin-tipi-contratto',
        component: AdminTipiContratto,
        meta: {
          infoText: infos.tipiContratto
        }
      },
      {
        path: 'tipi-bolletta',
        name: 'admin-tipi-bolletta',
        component: AdminTipiBolletta,
        meta: {
          infoText: infos.tipiBolletta
        }
      },
      {
        path: 'dipartimenti-unitn',
        name: 'admin-dipartimenti-unitn',
        component: AdminDipartimentiUnitn
      },
      {
        path: 'tariffe',
        name: 'admin-tariffe',
        component: AdminTariffe
      },
      {
        path: 'fabbricati',
        name: 'admin-fabbricati',
        component: AdminFabbricati
      },
      {
        path: 'fabbricati/:fid',
        name: 'admin-fabbricati-id',
        component: AdminFabbricatiId,
        props: true
      },
      {
        path: 'fabbricati/:fid/stanze/:sid',
        name: 'admin-fabbricati-id-stanze-id',
        component: AdminFabbricatiIdStanzeId,
        props: true
      },
      {
        path: 'tabellone',
        component: AdminTabellone,
        children: [
          {
            path: '',
            redirect: 'esporta'
          },
          {
            path: 'esporta',
            name: 'admin-tabellone-esporta',
            component: AdminTabelloneEsporta
          },
          {
            path: 'cronologia',
            name: 'admin-tabellone-cronologia',
            component: AdminTabelloneCronologia
          }
        ]
      },
      {
        path: 'contabilita',
        component: AdminContabilita,
        children: [
          {
            path: '',
            redirect: 'invia'
          },
          {
            path: 'invia',
            name: 'admin-contabilita-invia',
            component: AdminContabilitaInvia
          },
          {
            path: 'estrai',
            name: 'admin-contabilita-estrai',
            component: AdminContabilitaEstrai
          },
          {
            path: 'cronologia',
            name: 'admin-contabilita-cronologia',
            component: AdminContabilitaCronologia
          }
        ]
      },
      {
        path: 'contratti',
        component: AdminContratti,
        children: [
          {
            path: '',
            redirect: 'provvisori'
          },
          {
            path: 'provvisori',
            name: 'admin-contratti-provvisori',
            component: AdminContrattiProvvisori
          },
          {
            path: 'da-firmare',
            name: 'admin-contratti-da-firmare',
            component: AdminContrattiDaFirmare,
            meta: { infoText: infos.contrattiDaFirmare }
          },
          {
            path: 'da-visionare',
            name: 'admin-contratti-da-visionare',
            component: AdminContrattiDaVisionare
          },
          {
            path: 'futuri',
            name: 'admin-contratti-futuri',
            component: AdminContrattiFuturi
          },
          {
            path: 'attivi',
            name: 'admin-contratti-attivi',
            component: AdminContrattiAttivi
          },
          {
            path: 'terminati',
            name: 'admin-contratti-terminati',
            component: AdminContrattiTerminati
          },
        ]
      },
      {
        path: 'contratti/:cid',
        name: 'admin-contratti-id',
        component: AdminContrattiId,
        props: true
      },
      {
        path: 'presidenti',
        name: 'admin-presidenti',
        component: AdminPresidenti
      },
      {
        path: 'presidenti/:id',
        name: 'admin-presidenti-id',
        component: AdminPresidentiId,
        props: true
      },
      {
        path: 'ospiti',
        name: 'admin-ospiti',
        component: AdminOspiti,
        props: bindQuery('createDialogOpen')
      },
      {
        path: 'ospiti/:id',
        name: 'admin-ospiti-id',
        component: AdminOspitiId,
        props: true
      },
      {
        path: 'portinerie',
        name: 'admin-portinerie',
        component: AdminPortinerie
      },
      {
        path: 'log',
        name: 'admin-log',
        component: AdminLog
      },
      {
        path: '*',
        name: 'admin-not-found',
        meta: { noElevation: true },
        component: AdminNotFound
      },
    ]
  },
  {
    path: '/ragioneria',
    components: {
      default: Ragioneria,
      bar: RagioneriaBar,
      menu: RagioneriaMenu
    },
    meta: { authentication: [RuoloUtente.RAGIONERIA] },
    children: [
      {
        path: '',
        redirect: 'contabilita'
      },
      {
        path: 'controlli',
        name: 'ragioneria-controlli',
        component: RagioneriaControlliView
      },
      {
        path: 'user-info',
        name: 'ragioneria-user-info',
        component: RagioneriaUserInfo
      },
      {
        path: 'quietanzianti',
        name: 'ragioneria-quietanzianti',
        component: RagioneriaQuietanzianti
      },
      {
        path: 'conti-ricavi',
        name: 'ragioneria-conti-ricavi',
        component: RagioneriaContiRicavi
      },
      {
        path: 'tipi-ospite',
        name: 'ragioneria-tipi-ospite',
        component: RagioneriaTipiOspite,
        meta: {
          infoText: infos.tipiOspite
        }
      },
      {
        path: 'tipi-contratto',
        name: 'ragioneria-tipi-contratto',
        component: RagioneriaTipiContratto,
        meta: {
          infoText: infos.tipiContratto
        }
      },
      {
        path: 'tipi-bolletta',
        name: 'ragioneria-tipi-bolletta',
        component: RagioneriaTipiBolletta,
        meta: {
          infoText: infos.tipiBolletta
        }
      },
      {
        path: 'tariffe',
        name: 'ragioneria-tariffe',
        component: RagioneriaTariffe
      },
      {
        path: 'contabilita',
        component: RagioneriaContabilita,
        children: [
          {
            path: '',
            redirect: 'invia'
          },
          {
            path: 'invia',
            name: 'ragioneria-contabilita-invia',
            component: RagioneriaContabilitaInvia
          },
          {
            path: 'estrai',
            name: 'ragioneria-contabilita-estrai',
            component: RagioneriaContabilitaEstrai
          },
          {
            path: 'cronologia',
            name: 'ragioneria-contabilita-cronologia',
            component: RagioneriaContabilitaCronologia
          }
        ]
      },
      {
        path: 'contratti',
        component: RagioneriaContratti,
        children: [
          {
            path: '',
            redirect: 'provvisori'
          },
          {
            path: 'provvisori',
            name: 'ragioneria-contratti-provvisori',
            component: RagioneriaContrattiProvvisori
          },
          {
            path: 'da-firmare',
            name: 'ragioneria-contratti-da-firmare',
            component: RagioneriaContrattiDaFirmare,
            meta: { infoText: infos.contrattiDaFirmare }
          },
          {
            path: 'da-visionare',
            name: 'ragioneria-contratti-da-visionare',
            component: RagioneriaContrattiDaVisionare
          },
          {
            path: 'futuri',
            name: 'ragioneria-contratti-futuri',
            component: RagioneriaContrattiFuturi
          },
          {
            path: 'attivi',
            name: 'ragioneria-contratti-attivi',
            component: RagioneriaContrattiAttivi
          },
          {
            path: 'terminati',
            name: 'ragioneria-contratti-terminati',
            component: RagioneriaContrattiTerminati
          },
        ]
      },
      {
        path: 'contratti/:cid',
        name: 'ragioneria-contratti-id-bollette',
        component: RagioneriaContrattiId,
        props: true
      },
      {
        path: 'ospiti',
        name: 'ragioneria-ospiti',
        component: RagioneriaOspiti,
        props: bindQuery('createDialogOpen')
      },
      {
        path: 'ospiti/:id',
        name: 'ragioneria-ospiti-id',
        component: RagioneriaOspitiId,
        props: true
      },
      {
        path: 'portinerie',
        name: 'ragioneria-portinerie',
        component: RagioneriaPortinerie
      },
      {
        path: '*',
        name: 'ragioneria-not-found',
        meta: { noElevation: true },
        component: RagioneriaNotFound
      },
    ]
  },
  {
    path: '/sportello',
    components: {
      default: Sportello,
      bar: SportelloBar,
      menu: SportelloMenu
    },
    meta: { authentication: [RuoloUtente.SPORTELLO] },
    children: [
      {
        path: '',
        redirect: 'tabellone'
      },
      {
        path: 'user-info',
        name: 'sportello-user-info',
        component: SportelloUserInfo
      },
      {
        path: 'ricerca-stanze',
        name: 'sportello-ricerca-stanze',
        component: SportelloRicercaStanze
      },
      {
        path: 'controlli',
        name: 'sportello-controlli',
        component: SportelloControlli
      },
      {
        path: 'quietanzianti',
        name: 'sportello-quietanzianti',
        component: SportelloQuietanzianti
      },
      {
        path: 'tipi-studente',
        name: 'sportello-tipi-studente',
        component: SportelloTipiStudente,
        meta: {
          infoText: infos.tipiStudente
        }
      },
      {
        path: 'tipi-contratto',
        name: 'sportello-tipi-contratto',
        component: SportelloTipiContratto,
        meta: {
          infoText: infos.tipiContratto
        }
      },
      {
        path: 'tipi-bolletta',
        name: 'sportello-tipi-bolletta',
        component: SportelloTipiBolletta,
        meta: {
          infoText: infos.tipiBolletta
        }
      },
      {
        path: 'dipartimenti-unitn',
        name: 'sportello-dipartimenti-unitn',
        component: SportelloDipartimentiUnitn
      },
      {
        path: 'fabbricati',
        name: 'sportello-fabbricati',
        component: SportelloFabbricati
      },
      {
        path: 'fabbricati/:fid',
        name: 'sportello-fabbricati-id',
        component: SportelloFabbricatiId,
        props: true
      },
      {
        path: 'fabbricati/:fid/stanze/:sid',
        name: 'sportello-fabbricati-id-stanze-id',
        component: SportelloFabbricatiIdStanzeId,
        props: true
      },
      {
        path: 'tabellone',
        component: SportelloTabellone,
        children: [
          {
            path: '',
            redirect: 'esporta'
          },
          {
            path: 'esporta',
            name: 'sportello-tabellone-esporta',
            component: SportelloTabelloneEsporta
          },
          {
            path: 'cronologia',
            name: 'sportello-tabellone-cronologia',
            component: SportelloTabelloneCronologia
          }
        ]
      },
      {
        path: 'contratti',
        component: SportelloContratti,
        children: [
          {
            path: '',
            redirect: 'provvisori'
          },
          {
            path: 'provvisori',
            name: 'sportello-contratti-provvisori',
            component: SportelloContrattiProvvisori
          },
          {
            path: 'da-firmare',
            name: 'sportello-contratti-da-firmare',
            component: SportelloContrattiDaFirmare,
            meta: { infoText: infos.contrattiDaFirmare }
          },
          {
            path: 'da-visionare',
            name: 'sportello-contratti-da-visionare',
            component: SportelloContrattiDaVisionare
          },
          {
            path: 'futuri',
            name: 'sportello-contratti-futuri',
            component: SportelloContrattiFuturi
          },
          {
            path: 'attivi',
            name: 'sportello-contratti-attivi',
            component: SportelloContrattiAttivi
          },
          {
            path: 'terminati',
            name: 'sportello-contratti-terminati',
            component: SportelloContrattiTerminati
          },
        ]
      },
      {
        path: 'contratti/:cid',
        name: 'sportello-contratti-id',
        component: SportelloContrattiId,
        props: true
      },
      {
        path: 'ospiti',
        name: 'sportello-ospiti',
        component: SportelloOspiti,
        props: bindQuery('createDialogOpen')
      },
      {
        path: 'ospiti/:id',
        name: 'sportello-ospiti-id',
        component: SportelloOspitiId,
        props: true
      },
      {
        path: 'portinerie',
        name: 'sportello-portinerie',
        component: SportelloPortinerie
      },
      {
        path: '*',
        name: 'sportello-not-found',
        meta: { noElevation: true },
        component: SportelloNotFound
      },
    ]
  },
  {
    path: '/portineria',
    components: {
      default: Portineria,
      bar: PortineriaBar,
      menu: PortineriaMenu
    },
    meta: { authentication: [RuoloUtente.PORTINERIA] },
    children: [
      {
        path: '',
        redirect: 'ospiti'
      },
      {
        path: 'ricerca-stanze',
        name: 'portineria-ricerca-stanze',
        component: PortineriaRicercaStanze
      },
      {
        path: 'controlli',
        name: 'portineria-controlli',
        component: PortineriaControlli
      },
      {
        path: 'tabellone',
        component: PortineriaTabellone,
        children: [
          {
            path: '',
            redirect: 'esporta'
          },
          {
            path: 'esporta',
            name: 'portineria-tabellone-esporta',
            component: PortineriaTabelloneEsporta
          },
          {
            path: 'cronologia',
            name: 'portineria-tabellone-cronologia',
            component: PortineriaTabelloneCronologia
          }
        ]
      },
      {
        path: 'ospiti',
        name: 'portineria-ospiti',
        component: PortineriaOspiti,
        props: bindQuery('createDialogOpen')
      },
      {
        path: 'ospiti/:id',
        name: 'portineria-ospiti-id',
        component: PortineriaOspitiId,
        props: true
      },
      {
        path: 'portinerie',
        name: 'portineria-portinerie',
        component: PortineriaPortinerie
      },
      {
        path: '*',
        name: 'portineria-not-found',
        meta: { noElevation: true },
        component: PortineriaNotFound
      }
    ]
  },
  {
    path: '/controllo-alloggi',
    components: {
      default: ControlloAlloggi,
      bar: ControlloAlloggiBar,
      menu: ControlloAlloggiMenu
    },
    meta: { authentication: [RuoloUtente.CONTROLLO_ALLOGGI] },
    children: [
      {
        path: '',
        redirect: 'ospiti'
      },
      {
        path: 'ricerca-stanze',
        name: 'controllo-alloggi-ricerca-stanze',
        component: ControlloAlloggiRicercaStanze
      },
      {
        path: 'controlli',
        name: 'controllo-alloggi-controlli',
        component: ControlloAlloggiControlli
      },
      {
        path: 'tabellone',
        component: ControlloAlloggiTabellone,
        children: [
          {
            path: '',
            redirect: 'esporta'
          },
          {
            path: 'esporta',
            name: 'controllo-alloggi-tabellone-esporta',
            component: ControlloAlloggiTabelloneEsporta
          },
          {
            path: 'cronologia',
            name: 'controllo-alloggi-tabellone-cronologia',
            component: ControlloAlloggiTabelloneCronologia
          }
        ]
      },
      {
        path: 'ospiti',
        name: 'controllo-alloggi-ospiti',
        component: ControlloAlloggiOspiti,
        props: bindQuery('createDialogOpen')
      },
      {
        path: 'ospiti/:id',
        name: 'controllo-alloggi-ospiti-id',
        component: ControlloAlloggiOspitiId,
        props: true
      },
      {
        path: '*',
        name: 'controllo-alloggi-not-found',
        meta: { noElevation: true },
        component: ControlloAlloggiNotFound
      },
    ]
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
    else if (savedPosition) {
      return savedPosition;
    }
    else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(beforeEach);

export default router;
